import axios from 'axios';
import  { GET_ERRORS, SET_CURRENT_USER } from './types';
import setAuthToken from '../setAuthToken';
import jwt_decode from 'jwt-decode';
import { connection } from '../data/connection';

export const registroUser = (user, history) => dispatch => {
    axios.post('/api/users/registro', user)
        .then(res => history.push('login'))
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
 }

 export const loginUser = (user, history) => dispatch => {
    
    axios.post(`${connection.server}/api/auth/login`,
       user)
    .then( (response) => {
        //console.log(response.data.token);
        const  token  = response.data.token;
        localStorage.setItem('jwtToken', token);
        //console.log(token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
        history.push('/solicitudes')
        },)
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
 }
 
 export const setCurrentUser = decoded => {
     return{
         type: SET_CURRENT_USER,
         payload: decoded
     }
 }

 export const logoutUser = (history) => dispatch => {
     localStorage.removeItem('jwtToken');
     setAuthToken(false);
     dispatch(setCurrentUser({}));
     history.push('/login');
 }