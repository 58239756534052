import React,{ Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import  { Provider, connect } from 'react-redux';
import store from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authentication';

import Registro from './components/registro';
import Login from './components/login';
import MenuNotario from './components/menuNotario';
import Solicitudes from './components/solicitudes';
import FoliosGenerados from './components/foliosGenerados';
import Notificaciones from './components/notificaciones';
import Certificados from './components/certificados';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import 'semantic-ui-react';
import  PrivateRoute  from './PrivateRoute';



if(localStorage.jwtToken){
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));


  const currentTime = Date.now() / 1000;
  if(decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = '/'
  }
}

class App extends Component{
  render(){
  return (

    <Provider store={store}>
      <Router >
        <div>
        <MenuNotario />
          <Route exact path="/" component={Login} />
          <div  style={styles.container}>
           <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/solicitudes" component={Solicitudes} />
            <PrivateRoute exact path="/folios-generados" component={FoliosGenerados} />
            <PrivateRoute exact path="/notificaciones" component={Notificaciones} />
            <PrivateRoute exact path="/certificados" component={Certificados} />
          </div>
        </div>
      </Router>
    </Provider>
    );
 }
}
const styles = {
  container: {
    background: "white",
    marginTop: 15,
    },
  };

export default App;
 