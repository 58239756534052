import { 
    SET_FOLIOS_GENERADOS
} from '../constants';



export default (state = {}, action) => {
    switch(action.type){
        case SET_FOLIOS_GENERADOS:
        return {...state, folios: action.payload}
    default:
        return null;
    }
};