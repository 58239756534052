import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../actions/authentication';
import { Message } from 'semantic-ui-react';
import classnames from 'classnames';
import axios from 'axios';

class Login extends Component {

    constructor(){
        super();
        this.state = {
            usuario: '',
            password: '',
            errors: {},
            token: ""
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e){
      e.preventDefault();
      const { errors } = this.state;
      const user = {
        usuario: this.state.usuario,
        password: this.state.password,
      }
      this.props.loginUser(user, this.props.history);
       
    }

    componentDidMount(){
       if(this.props.auth.isAuthenticated) {
            this.props.history.push('/solicitudes');
        } 
    }
    componentWillMount(){
        if(!localStorage.jwtToken){
            this.props.history.push('/login');
        }
    }
   /* componentWillUnmount(nextProps){
        if(!nextProps.auth.isAuthenticated){
            this.props.history.push('/login');
        }
    }*/
    componentWillReceiveProps(nextProps){
        if(nextProps.auth.isAuthenticated){
            this.props.history.push('/solicitudes');
        } 

        if(nextProps.errors){
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    errores(){
        const {errors} = this.state;
        if(errors.password || errors.usuario){
            return  <Message color='red'  header='Intenta de nuevo' content={errors.message} />  
        }
    }

    render(){
        const {errors} = this.state;

        return(
        <div style={{marginTop:'20px', width:'700px', margin: '0 auto'}}>
        <img src='../images/portal-tittle.png' style={{paddingBottom:'40px', margin:'0 auto',marginLeft:'200px'}} />
        <h3 style={{color: '#747474', fontWeight:'bold', textAlign: 'end', margin: 0}}> Backoffice  </h3>
            <h3 style={{marginBottom: '50px', fontWeight:'bold'}}> Iniciar sesión  </h3>
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                <h6 style={{ fontWeight:'bold', fontSize:'14px', color:'rgb(113, 120, 133)'}}>  Usuario  </h6>
                    <input 
                     type="text"
                     placeholder="Usuario"
                     className={classnames('form-control form-control-lg')}
                     name="usuario"
                     onChange={this.handleInputChange}
                     value={this.state.usuario}
                    />
                </div>
                <div className="form-group">
                <h6 style={{ fontWeight:'bold', fontSize:'14px',color:'rgb(113, 120, 133)'}}> Contraseña  </h6>
                    <input 
                     type="password"
                     placeholder="Contraseña"
                     className={classnames('form-control form-control-lg', {'is-invalid': errors.password})}                     
                     name="password"
                     onChange={this.handleInputChange}
                     value={this.state.password}
                    />
                     
                </div>
                { errors.message &&
                    <div class="alert alert-danger" role="alert">
                    {errors.message}
                    </div>
                } 
                <div className="form-group">
                    <button type="submit" className="btn btn-info btn-lg btn-block" style={{backgroundColor:'rgb(44, 188, 179)'}}>
                        Iniciar Sesión
                    </button>
                </div>
            </form>
        </div>
        )
    }

}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})
export default connect(mapStateToProps, { loginUser })(Login)