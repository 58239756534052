import {
    SET_LISTADO_SOLICITUDES,
    GET_OFICINAS,
} from '../constants';

export const setListadoSolicitudes = (solicitudes) => ({
    type: SET_LISTADO_SOLICITUDES,
    payload: solicitudes
});

export const getOficinas = (oficinas) => ({
    type: GET_OFICINAS,
    payload: oficinas
});