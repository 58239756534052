import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment';
import {
  Button,
  Card,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Table,
} from "semantic-ui-react";
import { connection } from "../data/connection";
//import endPoints from "../utils/endPoints/notificacionesEndpoints";


const { Row, Column } = Grid;

class Certificados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openSuccess: false,
      oficinas: [],
      oficina: "",
      labelOficina: 'Selecciona una oficina',
      disableButton: true,
      messageNoInfo: true,
      messageApi: 'No tienes autorizacion',
      fecha: null,
      avisoTabla: {
        header: 'Indica una solicitud y selecciona una oficina',
        text: 'Para ver información debes proporcionar un numero de solicitud y seleccionar una oficina'
      }
    };
  }

  async componentDidMount() {
    this.getOficinas();
    //console.log(localStorage.jwtToken);
  }

  buscarSolicitud() {
    const { oficinaSelected, folio } = this.state;
    let url = `${connection.server}/api/inscripciones/BuscarSolicitud`;

    axios
      .post(url, { solicitud: folio, oficina: oficinaSelected })
      .then((response) => {
        if (response.data.message === "success") {
          this.setState({ data: response.data, disableButton: false, messageNoInfo: false });
        } else {
          const aviso = {
            header: 'No se encontró información',
            text: 'Prueba ingresando otro numero de solicutud u oficina'
          };
          this.setState({ messageNoInfo: true, data: null, avisoTabla: aviso});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

   formatearNotificacion() {
    const { data, fecha, oficinaSelected, folio } = this.state;

      let url = `${connection.server}/api/inscripciones/FormatearCertificado`;
      const body = {
        solicitud: folio,
        oficina: oficinaSelected
      };

      //Quitar la autorizacion del header del axios
      delete axios.defaults.headers.common['Authorization'];
     
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
      axios.defaults.headers.common['Content-Type'] = "application/json";
       axios({
        method: 'post',
        url: url,
        data: body
      }).then((response) => {
        console.log(response);
        console.log(response.data.message );
        const mensajeApi = response.data.message;
        this.setState({open: true, messageApi: mensajeApi });
      }).catch( (error) => {
        console.log(error);
       });
  }

 async getOficinas() {
    let url = `${connection.server}/api/oficinas/ObtenerOficinas`;

    await axios
      .get(url)
      .then((response) => {
        const oficinas = response.data.data.map((obj, index) => {
          return { key: obj._id, value: obj.value, text: obj.text };
        });
        this.setState({ oficinas });
        //this.props.getOficinas(oficinas);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  messageNoInfo() {
    const { messageNoInfo, avisoTabla } = this.state;

    if (messageNoInfo) {
      return (
        <Message
          warning
          icon="inbox"
          header={avisoTabla.header}
          content={avisoTabla.text}
          style={{ width: "60%", marginBottom: "15px", alignSelf: "center" }}
        />
      );
    }
  }

  setFolio(event) {
    this.setState({ folio: event.target.value });
  }
  selectOficina(event, data) {
    console.log(event)
    this.setState({ oficinaSelected: data.value, oficina: data.text });
  }
  close = () => this.setState({ open: false });

  renderTabla() {
    const { data } = this.state;

    return (
      <Card fluid style={{ padding: "0 12px" }}>
        <div
          style={{
            textAlign: "center",
            margin: 10,
            fontSize: "16px",
            color: "#1C1C1C",
          }}
        >
          <h4>Inscripciones en estatus solicitado</h4>
        </div>
        {this.messageNoInfo()}
        {!this.state.data ? null : (
          <Table basic="very" fixed selectable stripped color={"teal"}>
            <Table.Header
              style={{ overflow: "auto" }}
              style={{ fontSize: "12px" }}
            >
              <Table.Row textAlign={"center"}>
                <Table.HeaderCell style={{ color: "#8c8c8c" }} width={2}>
                  Solicitud
                </Table.HeaderCell>
                <Table.HeaderCell style={{ color: "#8c8c8c" }} width={2}>
                  Oficina
                </Table.HeaderCell>
                <Table.HeaderCell style={{ color: "#8c8c8c" }} width={2}>
                  Solicitante
                </Table.HeaderCell>
                <Table.HeaderCell style={{ color: "#8c8c8c" }} width={2}>
                  Volumen <br /> escritura
                </Table.HeaderCell>
                <Table.HeaderCell style={{ color: "#8c8c8c" }} width={2}>
                  Numero <br /> escritura
                </Table.HeaderCell>
                <Table.HeaderCell style={{ color: "#8c8c8c" }} width={2}>
                  Estatus
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body style={{ overflow: "auto" }}>
              {this.state.data.data &&
                this.state.data.data.map((item, index) => (
                  <Table.Row
                    key={index}
                    textAlign={"center"}
                    style={{ fontSize: "12px" }}
                    //onClick={this.handleRowClick()}
                  >
                    <Table.Cell width={2}> {item.nu_prelac}</Table.Cell>
                    <Table.Cell width={2}> {item.oficina_nombre}</Table.Cell>
                    <Table.Cell width={2}>
                      {" "}
                      {item.nombre_solicitante}
                    </Table.Cell>
                    <Table.Cell width={2}> {item.volumen_escritura}</Table.Cell>
                    <Table.Cell width={2}> {item.numero_escritura}</Table.Cell>
                    <Table.Cell width={2}> {item.estatus.text || item.status.label}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        )}
      </Card>
    );
  }

  render() {
    const { data, fecha, messageApi } = this.state;
    const { oficinas } = this.props;
    return (
      <Fragment>
        <Grid>
          <Row centered>
            <Column width={6}>
              <label>
                {/* <div style={styles.label}>
                  Folio asignado
                </div> */}
                <Input
                  action
                  type="number"
                  size="large"
                  placeholder="Buscar solicitud..."
                  name="folioAsignado"
                  value={this.state.folioAsignado}
                  onChange={(event) => this.setFolio(event)}
                >
                  <input />
                  <Button
                    type="submit"
                    name="Buscar"
                    color="teal"
                    onClick={() => this.buscarSolicitud()}
                  >
                    Buscar
                  </Button>
                </Input>
              </label>
            </Column>

            <Column width={2}>
              <Dropdown
                button
                floating
                labeled
                search
                icon="list"
                className='icon'
                color='teal'
                placeholder='Seleccionar oficina'
                options={this.state.oficinas}
                value={this.state.oficina}
                onChange={(event, data) => this.selectOficina(event, data)}
              />
            </Column>

            {/* <Column width={2}>
              <Button type='submit' name='Nuevo' color='teal' onClick={() => this.listaSolicitudes()} >
                <Icon name='angle double down' />
                  Cargar todo
              </Button>
            </Column> */}
          </Row>
        </Grid>
        <Grid>
          <Row centered style={{ marginTop: 20}}><h2>Correcion de Certificados Notario Digital</h2></Row>
          <Row style={{ marginTop: 5, margin: 64 }}>{this.renderTabla()}</Row>
        </Grid>
        {!data ? null :
        <Grid>
          <Row centered>
            {/* <Column width={2}>
              <Input
                icon="calendar"
                iconPosition="left"
                type="Date"
                name="fechaInicial"
                value={this.state.fechaInicial}
                onChange={(event) => {
                  this.setState({fecha: moment(event.target.value).format("D/M/YYYY")});
                }}
              >
                <input />
              </Input>
            </Column> */}
            <Column width={2}>
              <Button
                type="submit"
                name="Nuevo"
                true
                color="teal"
                disabled={this.state.disableButton}
                onClick={() => this.formatearNotificacion()}
                true
              >
                <Icon name="angle double down" />
                Corregir certificado
              </Button>
            </Column>
          </Row>
        </Grid>
  }
        <Grid>
          <Row style={{ marginTop: 20, margin: 64 }}>
            <Modal
              basic
              open={this.state.open}
              onClose={this.close}
              size={"small"}
              style={{
                margin: "0 auto",
                height: "220px",
                position: "relative",
              }}
              closeIcon
            >
              <Header icon="info circle" content="Algo occurió" />
              <Modal.Content>
                <h2>{messageApi}</h2>
              </Modal.Content>
              <Modal.Actions>
                <Button color="green" onClick={this.close}>
                  <Icon name="checkmark" /> Aceptar
                </Button>
              </Modal.Actions>
            </Modal>
          </Row>
        </Grid>
      </Fragment>
    );
  }
}

const styles = {
  label: {
    fontWeight: "bold",
    textAlign: "left",
    marginLeft: 20,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontSize: "16px",
    color: "#1C1C1C",
  },
};

Certificados.propTypes = {
  oficinas: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(state) {
  const { oficinas } = state;
  return { oficinas: oficinas };
}

const action = {};

export default connect(mapStateToProps, action)(Certificados);
