import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { setFoliosGenerados } from '../actions';
import endPoints from '../utils/endPoints/solicitudesEndPoints';
import { Grid, Segment, Loader, Dimmer, Header, Table, Icon, List, Item, Popup, Button, Card, Modal, Input, Image} from 'semantic-ui-react';
import { connect } from 'react-redux';
import setAuthToken from '../setAuthToken';
import { connection } from '../data/connection';
// import Paginator from './Paginator';
// import Moment from 'react-moment'
var dateFormat = require('dateformat');


const { Row, Column } = Grid;

class FoliosGenerados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            datos:{},
            item:{},
            estatus: '',
            folioElectronico:'',
            open: false,
            fechaInicial: null,
            fechaFinal:null,
            folioAsignado:'',
            filter: {
                FolioElectronico: 0,
                fechaSolicitud : null,
                FechaCreacion: null,
                pagina:1,
                recordsForPage: 10,
                    oficina: null,
                    libro: '',
                    seccion: '',
                    inscripcion: null,
                    nombre: '',
                    apellidoPaterno: '',
                    apellidoMaterno: '',
                    superficie: '',
                    observaciones: ''
            },
        };
    }

    async componentDidMount() {
        await this.listaFoliosGenerados()
       //console.log(localStorage.jwtToken);

    }
    componentWillMount(){

    }

    async listaFoliosGenerados() {
        await endPoints.getListadoSolicitudesGenerados().then((response) => {
           let { data } = response.data;
           this.setState({loading: false});
          
            let listaFoliosGenerados = data.map((folio) => {

                return {
                    ...folio,
                    oficina: folio.oficina,
                    libro: folio.libro,
                    seccion: folio.seccion,
                    inscripcion: folio.inscripcion,
                    nombre: folio.nombre,
                    apellidoPaterno: folio.apellidoPaterno,
                    apellidoMaterno: folio.apellidoMaterno,
                    superficie: folio.superficie,
                    observaciones: folio.observaciones,
                    folioElectronico: folio.folioElectronico
                    };
                })
            
            this.props.setFoliosGenerados(listaFoliosGenerados);
        })
        
    }
    /*
     async listaSolicitudes() {
        this.setState({ loading: true });
        //this.state.filter
        await endPoints.getListadoSolicitudes().then((response) => {
            let { data, solicitudesFiltradas } = response.data;
            
            let listaSolicitudes = data.map((solicitud) => {

                return {
                    ...solicitud,
                    oficina: solicitud.oficina,
                    libro: solicitud.libro,
                    seccion: solicitud.seccion,
                    inscripcion: solicitud.inscripcion,
                    nombre: solicitud.nombre,
                    apellidoPaterno: solicitud.apellidoPaterno,
                    apellidoMaterno: solicitud.apellidoMaterno,
                    superficie: solicitud.superficie,
                    observaciones: solicitud.observaciones
                };
            })
            if(this.state.filter.pagina === 1 && !data.lenght) {
                this.setState({ loading: false, numeroSolicitudes: solicitudesFiltradas});
                this.props.setListadoSolicitudes(listaSolicitudes);
            } else {
                const filter = {
                    ...this.state.filter,
                    pagina: 1,
                };
                this.setState({ filter }, () => this.listaSolicitudes());
            }
        })
    }
 */

    asignarFolio12(){
        let { item } = this.state;

        let self = this, 
        url = `${connection.serverNetCore}/api/solicitudesFolioMongoDB/${item.id}/terminarSolicitudSolicitudesFolio`

        axios.post(url, { 
        folioElectronico : this.state.folioElectronico,
        }, setAuthToken(localStorage.jwtToken))
        .then( (response) => {
            //console.log('response', response)
        this.setState({ open: false});
        window.location.reload();
        })
        .catch( (error) => {
        
        });
    }



    asignarFolio(){
        let { item } = this.state;

        let self = this, 
        url = `${connection.server}/api/solicitudes/${item._id}/terminar`
    
        axios.post(url, { 
        folioElectronico : this.state.folioElectronico,
        }, setAuthToken(localStorage.jwtToken))
        .then( (response) => {
            //console.log('response', response)
          this.setState({ open: false});
          window.location.reload();
         })
         .catch( (error) => {
           
         });
    }

    renderLoading() {
        const { loading } = this.state;

        if (loading) {
            return (
                <Dimmer active inverted style={{ marginTop: '10%'}}>
                    <Loader content="Cargando solicitudes"></Loader>
                </Dimmer>
            )
        }
    }

    openModal(item){
        this.setState({open: true, item: item})
    };
    
    openModalEliminar(item){
        this.setState({openEliminar: true, item: item})
    };
    
    onChangeFolio(event){
        if(event.target.value<1000000){
            this.setState({ folioElectronico:   event.target.value});
        }
    }
    onChangeFolioAsignado(event) {
        if(event.target.value<1000000){
            this.setState({ folioAsignado :   event.target.value});
        }
    }

    closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
        this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
      }
    
    close = () => this.setState({ open: false })
    
     onPageChange(page) {
        const {
            libro,
            seccion,
            inscripcion,
            nombre,
            apellidoPaterno,
            apellidoMaterno,
            superficie,
            observaciones,
            oficina
          } = this.state;
        
        const filter = {
            ...this.state.filter,
            oficina: oficina ? parseInt(oficina, 10) : null,
            libro,
            seccion,
            inscripcion,
            nombre,
            apellidoPaterno,
            apellidoMaterno,
            superficie,
            observaciones,
            pagina: page
        };
        this.setState( { filter }, () => this.listaFoliosGenerados());
     }

    async fechaBusqueda(){
        const primerafecha = this.state.fechaInicial;
        const segundafecha = this.state.fechaFinal;

        await endPoints.BusquedaFoliosGeneradosXPeriodo(primerafecha,segundafecha).then((response) => {

            let { data } = response.data;
             let listaFoliosGenerados = data.map((folio) => {
 
                 return {
                     ...folio,
                     oficina: folio.oficina,
                     libro: folio.libro,
                     seccion: folio.seccion,
                     inscripcion: folio.inscripcion,
                     nombre: folio.nombre,
                     apellidoPaterno: folio.apellidoPaterno,
                     apellidoMaterno: folio.apellidoMaterno,
                     superficie: folio.superficie,
                     observaciones: folio.observaciones,
                     folioElectronico: folio.folioElectronico
                 };
             })
             this.props.setFoliosGenerados(listaFoliosGenerados);
         
         })


    }
    
    async folioAsignada(){
        console.log('fecha asginada', this.state.folioAsignado)
        const folio = this.state.folioAsignado;
         await endPoints.BusquedaFoliosGeneradosXFolio(folio).then((response) => {
            let { data } = response.data;
            let listaFoliosGenerados = data.map((folio) => {

                return {
                    ...folio,
                    oficina: folio.oficina,
                    libro: folio.libro,
                    seccion: folio.seccion,
                    inscripcion: folio.inscripcion,
                    nombre: folio.nombre,
                    apellidoPaterno: folio.apellidoPaterno,
                    apellidoMaterno: folio.apellidoMaterno,
                    superficie: folio.superficie,
                    observaciones: folio.observaciones,
                    folioElectronico: folio.folioElectronico
                };
            })
            this.props.setFoliosGenerados(listaFoliosGenerados);

         })
    }
    
    renderTabla(){
        const { filter, numeroSolicitudes } = this.state;
        const { pagina, recordsForPage } = filter;
        const start = (pagina * recordsForPage ) - recordsForPage;
        const end = start + recordsForPage;

        //this.setState({ loading: false})
        const triggerPopup = (
           
            <Button icon style={{ background: 'transparent' }}>
              <Icon name="ellipsis vertical" style={{color:'#00b5ad'}}/>
            </Button>
          );
        return (
        <Card fluid style={{paddingLeft: 12, paddingRight: 12}}>
        <div style={{textAlign:'center', margin: 10, fontSize: '16px', color:'#1C1C1C'}}><h4>Inscripciones en estatus generados</h4></div>
        {this.renderLoading()}

            <Table basic='very' fixed selectable>
                {this.state.loading ? null :
                    <Table.Header style={{overflow: 'auto'}} style={{fontSize: '12px'}}>
                        <Table.Row textAlign={'center'}>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>PRIMER <br/> APELLIDO </Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>SEGUNDO<br />APELLIDO</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>CORREO <br /> ELECTRÓNICO</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>OFICINA</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>INSCRIPCION</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>LIBRO</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={1}>SECCIÓN</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>SUPERFICIE</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={4}>OBSERVACIONES <br /> NOTARIO</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>FOLIO ASIGNADO</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>FECHA SOLICITUD</Table.HeaderCell>
                            <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>FECHA CREACIÓN</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body style={{overflow: 'auto'}} >
                {this.props.folios && this.props.folios.map((item, index) => (
                    
                    <Table.Row key={index} textAlign={'center'} style={{fontSize: '12px'}}>
                        <Table.Cell width={2}> {item.nombre}</Table.Cell>
                        <Table.Cell width={2}> {item.apellidoPaterno}</Table.Cell>
                        <Table.Cell width={2}> {item.apellidoMaterno}</Table.Cell>
                        <Table.Cell width={2}> {item.email}</Table.Cell>
                        <Table.Cell width={2}> {`${item.oficina} - ${item.oficinaNombre}`}</Table.Cell>
                        <Table.Cell width={2}> {item.inscripcion}</Table.Cell>
                        <Table.Cell width={2}> {item.libro}</Table.Cell>
                        <Table.Cell width={1}> {item.seccion}</Table.Cell>
                        <Table.Cell width={2}> {item.superficie}</Table.Cell>
                        {/* <Table.Cell width={4} style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> {item.observaciones}</Table.Cell> */}
                        <Table.Cell width={4}> {item.observaciones}</Table.Cell> 
                        <Table.Cell width={2} style={{fontWeight: 'bold'}}> {item.folioElectronico}</Table.Cell>
                        <Table.Cell width={2} style={{fontWeight: 'bold'}}> { dateFormat(item.fechaSolicitud, "dd/mm/yyyy") }</Table.Cell>
                        <Table.Cell width={2} style={{fontWeight: 'bold'}}> {dateFormat(item.fechaRespuesta, "dd/mm/yyyy") }</Table.Cell>
                     </Table.Row>
                 ))} 
                </Table.Body>
            </Table>   
         </Card>      
        );
    }
 

    render() {
        const { open, closeOnEscape, closeOnDimmerClick } = this.state;

        return (
            <div>
            <Grid>
                <Row centered>
                    <Column width={6} >
                    <label><div style={{ fontWeight:'bold', textAlign:'center', borderTopLeftRadius: 5, borderTopRightRadius: 5,fontSize: '16px', color: '#1C1C1C', marginRight: '125px', marginLeft:20}}> Fecha inicial </div> </label>
                     <label> <div style={{ fontWeight:'bold',textAlign:'center', borderTopLeftRadius: 5, borderTopRightRadius: 5,fontSize: '16px', color: '#1C1C1C'}}> Fecha final </div>   </label>   
                        <Input  icon='calendar'  iconPosition='left'  type='Date' name='fechaInicial' value={this.state.fechaInicial} onChange={(event) => {this.setState({fechaInicial: event.target.value})}}>
                            <input />
                            <Input  icon='calendar'  iconPosition='left'  type='Date' name='fechaFinal' value={this.state.fechaFinal} onChange={(event) => {this.setState({fechaFinal : event.target.value})}}>
                                <input />
                                 <Button type='submit' name='Buscar' color='teal' onClick={() => this.fechaBusqueda()} > Buscar </Button>
                            </Input>
                        </Input>                            
                    </Column>

                    <Column width={7} >
                    <label><div style={{ fontWeight:'bold', textAlign:'left', marginLeft: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5, fontSize: '16px', color: '#1C1C1C'}}> Folio asignado </div>
                        <Input action type='number' size='large'  placeholder='Inserta folio asignado...' name='folioAsignado' value={this.state.folioAsignado}  onChange={ (event) => this.onChangeFolioAsignado(event)}>
                            <input />
                            <Button type='submit' name='Buscar' color='teal' onClick={() => this.folioAsignada()} > Buscar </Button>
                        </Input>
                        </label>
                    </Column>
                    <Column width={2}>
                        <Button type='submit' style={{marginTop: 20}} name='Nuevo' color='teal' onClick={() => this.listaFoliosGenerados()} ><Icon name='angle double down' /> Cargar todo </Button>
                    </Column>
                </Row>
            </Grid>

            <Grid>
                <Row style={{ marginTop: 20, margin: 64 }}>
                    {this.renderTabla()}
                     <Modal 
                        open={this.state.open}
                        closeOnEscape={closeOnEscape}
                        closeOnDimmerClick={closeOnDimmerClick}
                        onClose={this.close}
                        size={'small'}
                        style={{margin:'0 auto', height: '220px', position:'relative'}}
                        closeIcon>
                        <Header icon='archive' content='Asignar folio electrónico' />
                        <Modal.Content>
                            <Input placeholder='Inserte folio' type="number" focus fluid size={'small'} value={this.state.folioElectronico} onChange={ (event) => this.onChangeFolio(event)}/>
                        </Modal.Content>
                        <Modal.Actions>
                        <Button color='red' onClick={this.close}>
                            <Icon name='remove' /> Cancelar
                        </Button>
                        <Button color='green' onClick={() => this.asignarFolio()}>
                            <Icon name='checkmark' /> Asignar
                        </Button>
                        </Modal.Actions>
                    </Modal>   
                </Row>
            </Grid>
            
          </div>  
        );
    }



}



const styles = {
    container: {
      background: "white",
      padding: 10,
      marginTop: 25,
    },
};

FoliosGenerados.propTypes = {
    foliosGenerados: PropTypes.arrayOf(PropTypes.object),
    setFoliosGenerados: PropTypes.func
};

const mapStateToProps = (state) => {
    const foliosGenerados = state['foliosGenerados']

    return foliosGenerados
};

const action = {
    setFoliosGenerados
};



export default connect(mapStateToProps, action)(FoliosGenerados);