import axios from 'axios';
import { connection } from '../../data/connection';
import setAuthToken from '../../setAuthToken';

export default {
  async registrarNotificacion(data) {
    const { oficina, num_solicitud, estatus, mensaje, fecha } = data;
    let response = await axios.post(`${connection.server}/api/notificaciones/registrarNotificacionesSGR`,
    { oficina, num_solicitud, estatus, mensaje, fecha })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
      });
      return response
  },

  async buscarSolicitud(solicitud, oficina) {
    let response = await axios.post(`${connection.server}/api/inscripciones/BuscarSolicitud`,
    {solicitud, oficina})
      .then(function (response) {
        return response
      })
      .catch(function (error) {
      });
      return response
  },
};