
import React,{ Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

 const PrivateRoute = ({ component: Component,auth: auth, ...rest }) => (
  <Route {...rest} 
      render={props => (
        auth.isAuthenticated === true ? (
           <Component {...props} />
          ) : ( <Redirect to={{ pathname: '/login'}} /> )
  )} />
)

PrivateRoute.propTypes ={
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(mapStateToProps)(PrivateRoute);