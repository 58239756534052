import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { registroUser } from '../actions/authentication';
import classnames from 'classnames';

class Registro extends Component {

    constructor() {
        super();
        this.state = {
            nombre: '',
            correo: '',
            password: '',
            password_confirm: '',
            errors: {}
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        const user = {
            nombre: this.state.nombre,
            correo: this.state.correo,
            password: this.state.password,
            password_confirm: this.state.password_confirm
        }
        this.props.registroUser(user, this.props.history);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.auth.isAuthenticated){
            this.props.history.push("/")
        }
        if(nextProps.errors){
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    componentDidMount(){
        if(this.props.auth.isAuthenticated){
            this.props.history.push("/");
        }
    }

        render(){
            const { errors } = this.state;
            return(
                <div className='container' style={{marginTop: '50px', width: '700px'}}>
                <h2 style={{marginBottom: '40px'}}> Registro</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className='form-group'>
                        <input 
                        type="text"
                        placeholder="Nombre"
                        className={classnames('form-control form-control-lg', { 'is-invalid': errors.nombre})}
                        name="nombre"
                        onChange={this.handleInputChange}
                        value={this.state.name}
                        />
                        {errors.nombre && ( <div className="invalid-feedback"> {errors.nombre}</div>)}
                    </div>
                    <div className='form-group'>
                        <input 
                        type="email"
                        placeholder="Correo"
                        className={classnames('form-control form-control-lg', { 'is-invalid': errors.correo})}
                        name="correo"
                        onChange={this.handleInputChange}
                        value={this.state.correo}
                        />
                        {errors.correo && (<div className="invalid-feedback"> {errors.correo}</div>)}
                    </div>
                    <div className='form-group'>
                        <input 
                        type="password"
                        placeholder="Contraseña"
                        className={classnames('form-control form-control-lg', { 'is-invalid':errors.password})}
                        name="password"
                        onChange={this.handleInputChange}
                        value={this.state.password}
                        />
                        {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}                   
                     </div>
                    <div className='form-group'>
                        <input 
                        type="password"
                        placeholder="Confirmar constraseña"
                        className={classnames('form-control from-control-lg', { 'is-invalid': errors.password_confirm})}
                        name="password_confirm"
                        onChange={this.handleInputChange}
                        value={this.state.password_confirm}
                        />
                         {errors.password_confirm && (<div className="invalid-feedback">{errors.password_confirm}</div>)}
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-info btn-lg btn-block">
                            Registrar usuario
                        </button>
                    </div>
                </form>
            </div>
            )
        };
    
}

Registro.propTypes =  {
    registroUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, { registroUser }) (withRouter (Registro))