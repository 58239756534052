import axios from 'axios';
import { connection } from '../../data/connection';
import setAuthToken from '../../setAuthToken';
import jwt_decode from 'jwt-decode';

export default {

 async getListadoSolicitudes(){

      let response =  await axios.get(`${connection.server}/api/solicitudes/solicitudesFolios`)
        .then(function (response) {
           return response
          })
          .catch(function (error) {
          });
          return response
    },

    async getListadoSolicitudesGenerados(){

      let response =  await axios.get(`${connection.server}/api/solicitudes/solicitudesFoliosGenerados`)
        .then(function (response) {
           return response
          })
          .catch(function (error) {
          });
          return response
    },

    async getFoliosGenerados(){

      let response =  await axios.get(`${connection.server}/api/solicitudes/getFoliosGenerados`)
        .then(function (response) {
           return response
          })
          .catch(function (error) {
          });
          return response
    },


    async getListadoSolicitudes3(){

      let response =  await axios.get(`${connection.serverNetCore}/api/solicitudesFolioMongoDB/ObtenerSolicitudesFoliosSolicitados`)
         .then(function (response) {
           return response
          })
          .catch(function (error) {
          });
          return response
    },

    async asignarFolio2(item, folioElectronico){
      let response = await axios.post(`${connection.serverNetCore}/api/solicitudesFolioMongoDB/${item.id}/terminarSolicitudSolicitudesFolio`,
      { 
        folioElectronico: folioElectronico
      })
      .then(function (response) {
        const  token  = response.data.token;
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        return response
       })
       .catch(function (error) {
       });
       return response
  }
,

async BusquedaFoliosGeneradosXFolio(folio)
{
  let response = await axios.post(`${connection.server}/api/solicitudes/SearchFoliosGeneradosXFolio`,
  {
     folio : folio
  })
  .then(function (response) {
    // const  token  = response.data.token;
    // localStorage.setItem('jwtToken', token);
    // setAuthToken(token);
    return response
   })
   .catch(function (error) {
   });
   return response
},

async BusquedaFoliosGeneradosXPeriodo(fecha_inicial,fecha_final)
{
  console.log('fechass', fecha_final, fecha_inicial)
  let response = await axios.post(`${connection.server}/api/solicitudes/SearchFoliosGeneradosXPeriodo`,
  {
    fecha_inicial : fecha_inicial,
    fecha_final : fecha_final
  })
  .then(function (response) {
    console.log('response', response.data)
    // const  token  = response.data.token;
    // localStorage.setItem('jwtToken', token);
    // setAuthToken(token);
    return response
   })
   .catch(function (error) {
     console.log('errooor', error)
   });
   return response
},
 
  async asignarFolio(item, folioElectronico){
      let response = await axios.post(`${connection.server}/api/solicitudes/${item.id}/terminar`,
      { 
        folioElectronico: folioElectronico
      })
      .then(function (response) {
        const  token  = response.data.token;
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        return response
        })
        .catch(function (error) {
        });
        return response
  }
,

async EliminarSolicitudFolio(item){
  let response = await axios.post(`${connection.server}/api/solicitudes/${item.id}/EliminarSolicitudFolio`
  )
  .then(function (response) {
    const  token  = response.data.token;
    localStorage.setItem('jwtToken', token);
    setAuthToken(token);
    return response
   })
   .catch(function (error) {
     return error
   });
   return response
}

};