import { 
    SET_LISTADO_SOLICITUDES,
    GET_OFICINAS,
} from '../constants';

const initialState = {
    oficinas: null
}

export default (state = {}, action) => {
    switch(action.type){
        case SET_LISTADO_SOLICITUDES:
            return {...state, lista: action.payload}
        case GET_OFICINAS:
            return {...state, oficinas: action.payload}
    default:
        return null;
    }
};