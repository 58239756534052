import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import solicitud from  './solicitudesReducer';
import foliosGenerados from './foliosGeneradosReducer';

export default combineReducers({
    errors: errorReducer,
    auth: authReducer, 
    solicitudes: solicitud,
    foliosGenerados: foliosGenerados

});