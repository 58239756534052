import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { setListadoSolicitudes, getOficinas } from '../actions';
import endPoints from '../utils/endPoints/solicitudesEndPoints';
import { Grid, Segment, Loader, Dimmer, Header, Table, Icon, List, Item, Popup, Button, Card, Modal, Input, Image, Dropdown, Message} from 'semantic-ui-react';
import { connect } from 'react-redux';
import setAuthToken from '../setAuthToken';
import { connection } from '../data/connection';
import Paginator from './Paginator';
var dateFormat = require('dateformat');

const { Row, Column } = Grid;

class Solicitudes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            datos:{},
            item:{},
            estatus: '',
            folioElectronico:'',
            open: false,
            oficinas: [],
            noInfoMessage: false,
            filter: {
                numeroSolicitudes: 0,
                pagina:1,
                recordsForPage: 10,
                    oficina: null,
                    libro: '',
                    seccion: '',
                    inscripcion: null,
                    nombre: '',
                    apellidoPaterno: '',
                    apellidoMaterno: '',
                    superficie: '',
                    observaciones: ''
            },
        };
    }

    async componentDidMount() {
        await this.listaSolicitudes()
        this.getOficinas()
       //console.log(localStorage.jwtToken);

    }

    async listaSolicitudes() {
        await endPoints.getListadoSolicitudes().then((response) => {
           let { data } = response.data;
           // let { data } = response;
            let listaSolicitudes = data.map((solicitud) => {

                return {
                    ...solicitud,
                    oficina: solicitud.oficina,
                    libro: solicitud.libro,
                    seccion: solicitud.seccion,
                    inscripcion: solicitud.inscripcion,
                    nombre: solicitud.nombre,
                    apellidoPaterno: solicitud.apellidoPaterno,
                    apellidoMaterno: solicitud.apellidoMaterno,
                    superficie: solicitud.superficie,
                    observaciones: solicitud.observaciones
                };
            })
            this.setState({ noInfoMessage: false })
            this.props.setListadoSolicitudes(listaSolicitudes);
        
        })
    }
    /*
     async listaSolicitudes() {
        this.setState({ loading: true });
        //this.state.filter
        await endPoints.getListadoSolicitudes().then((response) => {
            let { data, solicitudesFiltradas } = response.data;
            
            let listaSolicitudes = data.map((solicitud) => {

                return {
                    ...solicitud,
                    oficina: solicitud.oficina,
                    libro: solicitud.libro,
                    seccion: solicitud.seccion,
                    inscripcion: solicitud.inscripcion,
                    nombre: solicitud.nombre,
                    apellidoPaterno: solicitud.apellidoPaterno,
                    apellidoMaterno: solicitud.apellidoMaterno,
                    superficie: solicitud.superficie,
                    observaciones: solicitud.observaciones
                };
            })
            if(this.state.filter.pagina === 1 && !data.lenght) {
                this.setState({ loading: false, numeroSolicitudes: solicitudesFiltradas});
                this.props.setListadoSolicitudes(listaSolicitudes);
            } else {
                const filter = {
                    ...this.state.filter,
                    pagina: 1,
                };
                this.setState({ filter }, () => this.listaSolicitudes());
            }
        })
    }
 */

    asignarFolio12(){
        let { item } = this.state;

        let self = this, 
        url = `${connection.serverNetCore}/api/solicitudesFolioMongoDB/${item.id}/terminarSolicitudSolicitudesFolio`

        axios.post(url, { 
        folioElectronico : this.state.folioElectronico,
        }, setAuthToken(localStorage.jwtToken))
        .then( (response) => {
            //console.log('response', response)
        this.setState({ open: false});
        window.location.reload();
        })
        .catch( (error) => {
        
        });
    }

    EliminarSolicitud(ID){
        let {item} = this.state;
        
        let self = this, 
        url = `${connection.server}/api/solicitudes/${ID}/EliminarSolicitudFolio`
    
        axios.post(url, { 
        folioElectronico : 1,
        }, setAuthToken(localStorage.jwtToken))
        .then( (response) => {
            //console.log('response', response)
          this.setState({ open: false});
          window.location.reload();
         })
         .catch( (error) => {
           
         });
    }

    asignarFolio(){
        let { item } = this.state;

        let self = this, 
        url = `${connection.server}/api/solicitudes/${item._id}/terminar`
    
        axios.post(url, { 
        folioElectronico : this.state.folioElectronico,
        }, setAuthToken(localStorage.jwtToken))
        .then( (response) => {
            //console.log('response', response)
          this.setState({ open: false});
          window.location.reload();
         })
         .catch( (error) => {
           
         });
    }

    getOficinas(){
        let url = `${connection.server}/api/oficinas/ObtenerOficinas`;

        axios.get(url).then((response) => {
            const oficinas = response.data.data.map((obj, index) => {
                return {key: obj._id, value: obj.value, text: obj.text}
            })
            this.setState({ oficinas });
            this.props.getOficinas(oficinas);
        }).catch((error) => {
            console.log(error);
        })
    }

    getOficina(oficina){
        let url = `${connection.server}/api/solicitudes/ObtenerSolicitudFoliosXOficina`

        axios.post(url, { value: oficina }).then((response) => {
            const data = response.data;
            console.log(response.data);
            if(data.isValid === true) {
                let listaSolicitudes = response.data.data.map((solicitud) => {

                    return {
                        ...solicitud,
                        oficina: solicitud.oficina,
                        libro: solicitud.libro,
                        seccion: solicitud.seccion,
                        inscripcion: solicitud.inscripcion,
                        nombre: solicitud.nombre,
                        apellidoPaterno: solicitud.apellidoPaterno,
                        apellidoMaterno: solicitud.apellidoMaterno,
                        superficie: solicitud.superficie,
                        observaciones: solicitud.observaciones
                    };
                })
                this.setState({ noInfoMessage: false })
                this.props.setListadoSolicitudes(listaSolicitudes);
            } else {
                this.setState({ noInfoMessage: true });
            }
        })
    }

    renderLoading() {
        const { loading } = this.state;

        if (loading) {
            return (
                <Grid container>
                    <Column>
                        <Segment style={{ height: 100 }}>
                            <Dimmer active inverted style={{ height: 100 }}>
                                <Loader content="Cargando solicitudes"></Loader>
                            </Dimmer>
                        </Segment>
                    </Column>
                </Grid>
            )
        }
    }

    messageNoInfo(){
        const { noInfoMessage } = this.state;

        if (noInfoMessage) {
            return (
                <Message warning icon='inbox' header='No se encontró información' content='Prueba seleccionando otra oficina' style={{ width: '60%', marginBottom: '15px', alignSelf: 'center'}} />
            )
        }
    }

    openModal(item){
        this.setState({open: true, item: item})
    };
    
    openModalEliminar(item){
        this.setState({openEliminar: true, item: item})
    };
    
    onChangeFolio(event){
      //  if(event.target.value<1000000){
            this.setState({ folioElectronico:   event.target.value});
       // }
    }

    closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
        this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
      }
    
    close = () => this.setState({ open: false })
    
     onPageChange(page) {
        const {
            libro,
            seccion,
            inscripcion,
            nombre,
            apellidoPaterno,
            apellidoMaterno,
            superficie,
            observaciones,
            oficina
          } = this.state;
        
        const filter = {
            ...this.state.filter,
            oficina: oficina ? parseInt(oficina, 10) : null,
            libro,
            seccion,
            inscripcion,
            nombre,
            apellidoPaterno,
            apellidoMaterno,
            superficie,
            observaciones,
            pagina: page
        };
        this.setState( { filter }, () => this.listaSolicitudes());
     }

    handleRowClick(){
        console.log('clickazo!')
    }
    
    renderTabla(){
        const { filter, numeroSolicitudes } = this.state;
        const { pagina, recordsForPage } = filter;
        const start = (pagina * recordsForPage ) - recordsForPage;
        const end = start + recordsForPage;

        //this.setState({ loading: false})
        const triggerPopup = (
           
            <Button icon style={{ background: 'transparent' }}>
              <Icon name="ellipsis vertical" style={{color:'#00b5ad'}}/>
            </Button>
          );
        return (
        <Card fluid style={{paddingLeft: 12, paddingRight: 12}}>
        <div style={{textAlign:'center', margin: 10, fontSize: '16px', color:'#1C1C1C'}}>
            <h4>Inscripciones en estatus solicitado</h4>
        </div>
        {this.messageNoInfo()}
            {this.state.noInfoMessage ? null :
            <Table  basic='very' fixed selectable stripped color={'teal'}>
                <Table.Header style={{overflow: 'auto'}} style={{fontSize: '12px'}}>
                    <Table.Row textAlign={'center'}>
                        <Table.HeaderCell style={{color: '#8c8c8c'}} width={2}>NOMBRE</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>PRIMER <br/> APELLIDO </Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>SEGUNDO<br />APELLIDO</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>CORREO <br /> ELECTRÓNICO</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>OFICINA</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>INSCRIPCION</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>LIBRO</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={1}>SECCIÓN</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>SUPERFICIE</Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}} width={5}>OBSERVACIONES <br /> NOTARIO</Table.HeaderCell>

                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>FECHA SOLICITUD </Table.HeaderCell>
                        <Table.HeaderCell style={{color: '#8c8c8c'}}  width={2}>ACCIÓN </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body style={{overflow: 'auto'}}>
                {this.props.lista && this.props.lista.map((item, index) => (
                    
                    <Table.Row key={index} textAlign={'center'} style={{fontSize: '12px'}} onClick={() => this.handleRowClick()}>
                        <Table.Cell width={2}> {item.nombre}</Table.Cell>
                        <Table.Cell width={2}> {item.apellidoPaterno}</Table.Cell>
                        <Table.Cell width={2}> {item.apellidoMaterno}</Table.Cell>
                        <Table.Cell width={2}> {item.email}</Table.Cell>
                        <Table.Cell width={2}> {`${item.oficina} - ${item.oficinaNombre}`}</Table.Cell>
                        <Table.Cell width={2}> {item.inscripcion}</Table.Cell>
                        <Table.Cell width={2}> {item.libro}</Table.Cell>
                        <Table.Cell width={1}> {item.seccion}</Table.Cell>
                        <Table.Cell width={2}> {item.superficie}</Table.Cell>
                        {/* <Table.Cell width={3} style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> {item.observaciones}</Table.Cell> */}
                        <Table.Cell width={3}> {item.observaciones}</Table.Cell>

                        <Table.Cell width={2}> {dateFormat(item.fechaSolicitud, "dd/mm/yyyy")}</Table.Cell>
                        <Table.Cell width={2}>
                            {item.estatus !== "generado" &&    
                            <Popup 
                            trigger={triggerPopup}
                            flowing
                            hoverable
                            position="left center"
                            on="click">
                                <Grid>
                                    <Column>
                                        <List link>
                                            <Item as="a" onClick={() => this.openModal(item)}>Asignar folio electrónico</Item>
                                            <Item as="a" onClick={() => this.EliminarSolicitud(item._id)}>Eliminar Solicitud De Folio</Item>
                                         </List>
                                    </Column>
                                </Grid>
                            </Popup> 
                            }
                            {item.estatus === 'generado' &&  <Header style={{fontSize: 14, fontWeight: 200, marginTop: 5}}> {item.folioElectronico}</Header>}
                         </Table.Cell>
                     </Table.Row>
                 ))} 
                </Table.Body>
            </Table>  
    } 
         </Card>      
        );
    }
 

    render() {

        const { open, closeOnEscape, closeOnDimmerClick } = this.state;

        return (
            <div>

            <Grid>
                    <Column floated='right' width={2} >
                        <Dropdown button floating labeled search className='icon' icon='list' color='teal' text='Seleccionar oficina' options={this.state.oficinas} onChange={(event, data) => this.getOficina(data.value)} />
                    </Column>

                    <Column width={2}>
                        <Button type='submit' name='Nuevo' color='teal' onClick={() => this.listaSolicitudes()} ><Icon name='angle double down' /> Cargar todo </Button>
                    </Column>
            </Grid>

            <Grid>
                <Row style={{ marginTop: 20, margin: 64 }}>
                    {this.renderTabla()}
                     <Modal 
                        open={this.state.open}
                        closeOnEscape={closeOnEscape}
                        closeOnDimmerClick={closeOnDimmerClick}
                        onClose={this.close}
                        size={'small'}
                        style={{margin:'0 auto', height: '220px', position:'relative'}}
                        closeIcon>
                        <Header icon='archive' content='Asignar folio electrónico' />
                        <Modal.Content>
                            <Input placeholder='Inserte folio' type="text" focus fluid size={'small'} value={this.state.folioElectronico}
                             onChange={ (event) => this.onChangeFolio(event)}
                             />
                        </Modal.Content>
                        <Modal.Actions>
                        <Button color='red' onClick={this.close}>
                            <Icon name='remove' /> Cancelar
                        </Button>
                        <Button color='green' onClick={() => this.asignarFolio()}>
                            <Icon name='checkmark' /> Asignar
                        </Button>
                        </Modal.Actions>
                    </Modal>   
                </Row>
            </Grid>
            
          </div>  
        );
    }



}

const styles = {
    container: {
      background: "white",
      padding: 10,
      marginTop: 25,
    },
};

Solicitudes.propTypes = {
    solicitudes: PropTypes.arrayOf(PropTypes.object),
    setListadoSolicitudes: PropTypes.func,
    getOficinas: PropTypes.func,
};

const mapStateToProps = (state) => {
    const solicitudes = state['solicitudes']
    return solicitudes
};

const action = {
    setListadoSolicitudes,
    getOficinas,
};

export default connect(mapStateToProps, action)(Solicitudes);