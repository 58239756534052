import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../actions/authentication';
import { withRouter } from 'react-router-dom';
import { Menu, Segment, Icon, Button } from 'semantic-ui-react';

class MenuNotario extends Component {

    constructor(props){
        super(props);
        this.state ={
        activeItem:  'solicitudes'
        
        }
    }

    handleItemClick = ( e, { name }) => this.setState({ activeItem: name })


    onLogout(e){
        e.preventDefault();
        this.props.logoutUser(this.props.history);
        this.setState({ activeItem: 'solicitudes'})
    }

    render(){

        const {isAuthenticated, user,  } = this.props.auth;
        const { activeItem } = this.state;
        const authLinks = (

            <Menu pointing secondary>
                <Menu.Item>
                <Link className="navbar-brand" to="/"><img src="../images/portal-tittle.png" style={{marginTop: 0, width:'40%'}} /></Link>
                </Menu.Item>
                <Link to='/solicitudes' style={{marginTop:'2%'}}>
                    <Menu.Item name='solicitudes' style={{fontSize: 18}} color={'teal'} active={activeItem === 'solicitudes'} onClick={this.handleItemClick}>
                        Solicitudes de folios
                     </Menu.Item>
                </Link>

                <Link to='/folios-generados' style={{marginTop:'2%'}}>
                    <Menu.Item  name='foliosGenerados'  style={{fontSize: 18}} color={'teal'} active={activeItem === 'foliosGenerados'} onClick={this.handleItemClick}>
                    Folios generados
                    </Menu.Item>                
                </Link>

                <Link to='/notificaciones' style={{marginTop:'2%'}}>
                    <Menu.Item  name='notificaciones'  style={{fontSize: 18}} color={'teal'} active={activeItem === 'notificaciones'} onClick={this.handleItemClick}>
                    Notificaciones
                    </Menu.Item>                
                </Link>

                <Link to='/certificados' style={{marginTop:'2%'}}>
                    <Menu.Item  name='certificados'  style={{fontSize: 18}} color={'teal'} active={activeItem === 'certificados'} onClick={this.handleItemClick}>
                    Certificados
                    </Menu.Item>                
                </Link>
                
                <Menu.Menu position={'right'} style={{marginRight:40, paddingTop: -15}}>
                    <Menu.Item
                    name={user.nombre}
                    style={{fontWeight:'bold', fontSize: 16}}
                    icon={<Icon name='user outline' title={user.nombre} color='teal' style={{width:'25px'}} /> }
                    > 
                    </Menu.Item>

                    <Menu.Item >
                     <Button name='Cerrar sesión'
                    active={activeItem === 'cerrar'}
                    onClick={this.onLogout.bind(this)}>Cerrar sesión</Button>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

             
        )
        const invitadoLinks = (

            <Menu secondary>
                <Menu.Menu position='left' style={{margin: 20}}>
                {/* <Link to="/login"><img src="../images/sinaloa_escudo.png" style={{width:'4%', margin:15 }} /></Link> */}
                </Menu.Menu>
            </Menu>
    
        )
        return(
            <div >
                {isAuthenticated ? authLinks : invitadoLinks}
            </div>
        )
    }

}

MenuNotario.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps, {logoutUser})(withRouter(MenuNotario));